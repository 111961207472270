import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(en);

export default countries;

export const shortenCountryName = name => {
  const toSkip = ['of', 'the', 'in'];
  const words = name
    .replace(/\(.+\)\s?|,.+/g, '')
    .trim()
    .split(' ');

  if (words.length > 1) {
    return words.reduce((str, w) => {
      if (toSkip.indexOf(w) < 0) {
        // eslint-disable-next-line no-param-reassign
        str += w.slice(0, 1).toUpperCase();
      }
      return str;
    }, '');
  }

  return words.join(' ');
};

/*
 * Return country's currency, AED by default
 * @param {string} alpha2 - ISO 3166-1 alpha2 code
 * @returns {string} The 2/3 char code
 * */
export const currencyFromAlpha2 = alpha2 => {
  switch (alpha2) {
    case 'SA':
      return 'SAR';
    case 'BH':
      return 'BHR';
    case 'OM':
      return 'OMR';
    case 'KW':
      return 'KWD';
    default:
      return 'AED';
  }
};

export const prefixISDFromAlpha2 = alpha2 => {
  switch (alpha2) {
    case 'SA':
      return '+966';
    case 'BH':
      return '+973';
    case 'OM':
      return '+968';
    case 'KW':
      return '+965';
    default:
      return '+971';
  }
};
