/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import b from 'b_';
import { useFormik } from 'formik';
import { Redirect } from 'react-router-dom';
import { PLAN_RATE_SLUGS, IM_DISPLAY_NAME, CHECKOUT_SOURCE_PL } from 'utils/constants';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import snakeToCamel from 'utils/snakeToCamel';
import Input from 'spotii-ui/Input';
import Button from 'spotii-ui/Button';
import message from 'spotii-ui/Message';
import TextArea from 'spotii-ui/Textarea';
import RadioGroup from 'spotii-ui/RadioGroup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  createOrderForPayment,
  checkUniqueCheckoutReference,
  generateUniqueCheckoutReference,
} from 'api';
import FormError from 'spotii-ui/Form/FormError';
import Icon from 'spotii-ui/Icon';
import formSerializer from './form/serializer';
import errorsSerializer from './form/errors';
import store from '../../store';
import copyTextToClipboard from '../../utils/copyToClipboard';

import './CreateOrderForm.scss';

const cls = b.with('settings');

const OrderPaymentForm = ({ appState, continueTo, onSuccess }) => {
  const { t } = useTranslation();
  const { displayName, linkBankAccount } = useSelector(state => state.application);
  const currLang = useSelector(state => state.language.language);

  const requireName = displayName === IM_DISPLAY_NAME;
  const [selectedPlanRateSlug, setSelectedPlanRateSlug] = React.useState(
    PLAN_RATE_SLUGS.INSTALLMENTS,
  );

  const validate = (appState, values) => {
    const errors = {};
    const swiftRegEx = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/i;
    const requiredFields = {
      ...(appState.requiresCustDetailsOnCheckout && {
        orderNumber: t('orderNumberRequired'),
        orderTotal: t('orderTotalRequired'),
        email: t('emailRequired'),
        phone: t('phoneRequired'),
      }),
      ...(!appState.requiresCustDetailsOnCheckout && {
        orderNumber: t('orderNumberRequired'),
        orderTotal: t('orderTotalRequired'),
      }),
    };

    Object.keys(requiredFields).forEach(key => {
      if (!values[key]) {
        errors[key] = requiredFields[key];
      }
    });
    const defaultOrderMin = parseInt(appState.orderMinAmount, 10);
    let orderMinAmount = defaultOrderMin;

    if (appState.canRestrictCheckoutType) {
      const currentPlanRate = appState.planRates.find(rate => rate.slug === values.slug);
      orderMinAmount = parseInt(currentPlanRate.order_min_amount, 10);
    }

    const total = parseFloat(values.orderTotal);

    if (isNaN(total) || total < orderMinAmount) {
      errors.orderTotal = t('orderShouldBeAbove', {
        min_amount: orderMinAmount,
        currency: appState.currency,
      });
    }

    const pattern = new RegExp(/^\+?\+[0-9]{11,13}$/);
    if (appState.requiresCustDetailsOnCheckout && !pattern.test(values.phone)) {
      errors.phone = t('phoneNotValid');
    }
    return errors;
  };

  const userHasRole = appState.loggedEmployee.role;
  let userCanBeRedirected = true;
  if (userHasRole) {
    const userPermissions = appState.loggedEmployee.role.permissions;
    userCanBeRedirected = userPermissions.includes('checkouts_read');
  }
  const provideDetails = false;
  const [uniqueRefFailed, setUniqueRefFailed] = React.useState(false);

  const handleBlurRef = e => {
    const checkoutReference = e.target.value;
    const { merchantId } = store.getState().application.currentUser.merchant;
    checkUniqueCheckoutReference(checkoutReference, merchantId).then(res => {
      if (res.data.exists === 'true') {
        setUniqueRefFailed(true);
      }
    });
  };
  const [uniqueRef, setUniqueRef] = React.useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      orderNumber: '',
      orderTotal: '',
      itemTitle: '',
      unitPrice: '',
      description: '',
      quantity: '',
      slug: PLAN_RATE_SLUGS.INSTALLMENTS,
      firstName: '',
      lastName: '',
    },
    validate: validate.bind(this, appState),

    // validationSchema,
    onSubmit: async (values, actions) => {
      const data = formSerializer(values);
      const checkoutData = {
        reference: uniqueRef,
        display_reference: uniqueRef,
        source: CHECKOUT_SOURCE_PL,
        description: `Order #${uniqueRef} Description:${data.description}`,
        total: data.orderTotal,
        currency: appState.currency,
        confirm_callback_url: '',
        reject_callback_url: '',
        order: {
          employee_id: appState.loggedEmployee.employee_id,
          tax_amount: data.taxAmount ? data.taxAmount : null,
          shipping_amount: data.shippingAmount ? data.shippingAmount : null,
          discount: data.discount ? data.discount : null,
          customer: {
            email: data.email,
            phone: data.phone,
          },
          billing_address: {},
          shipping_address: {},
        },
      };

      if (requireName) {
        checkoutData.order.customer.first_name = data.firstName;
        checkoutData.order.customer.last_name = data.lastName;
      }

      if (appState.canRestrictCheckoutType && values.slug === PLAN_RATE_SLUGS.PAY_NOW) {
        checkoutData.plan = 'pay-now';
      }

      if (data.itemTitle === '' || data.quantity === '' || data.unitPrice === '') {
        checkoutData.order.lines = null;
      } else {
        checkoutData.order.lines = [
          {
            title: data.itemTitle,
            quantity: data.quantity,
            price: data.unitPrice,
          },
        ];
      }
      actions.setStatus();

      try {
        const createdOrder = await createOrderForPayment(checkoutData);
        actions.setStatus('success');
        message.success(t('paymentLingCreationSuccess'));
        copyTextToClipboard('Payment Link', createdOrder.checkout_url);
        if (onSuccess) {
          if (userCanBeRedirected) {
            onSuccess(data);
          } else {
            actions.resetForm();
            setUniqueRef('');
          }
        }
      } catch (e) {
        console.warn(e);
        const errors = errorsSerializer(e.errors);
        if (errors) {
          actions.setErrors(errors);
        } else {
          message.error(
            <span>
              {`${t('formErrorTryAgainContact')} `}
              <a href="mailto:">merchantsupport@spotii.me</a>
            </span>,
          );
          actions.setStatus('error');
        }
      }
    },
  });
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } = formik;

  const handleChangeRef = e => {
    values.orderNumber = e.target.value;
    setUniqueRef(e.target.value);
  };
  const [generationLoading, setGenerationLoading] = React.useState(false);
  const handleGenerateNumberClick = () => {
    setGenerationLoading(true);
    const { merchantId } = store.getState().application.currentUser.merchant;
    generateUniqueCheckoutReference(merchantId).then(res => {
      const generatedRef = res.data.unique_ref.toUpperCase();
      setUniqueRef(generatedRef);
      values.orderNumber = generatedRef;
      setGenerationLoading(false);
      setUniqueRefFailed(false);
    });
  };

  // const handleProvideDetailsChange = e => {
  //   setProvideDetails(e.target.checked);
  // };

  if (formik.status === 'success' && continueTo) {
    return <Redirect to={continueTo} push />;
  }

  if (formik.status === 'error') {
    // Show generic error message
  }

  const getError = key => touched[key] && errors[key];

  return (
    <div className={b('address-form')}>
      <Form onSubmit={formik.handleSubmit}>
        <FormRow>
          <h4 style={{ display: 'flex' }}>{` ${t('customerDetails')} `}</h4>
        </FormRow>

        {requireName && (
          <>
            <InputRow
              name="firstName"
              label={t('firstName')}
              type="text"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getError('firstName')}
            />

            <InputRow
              name="lastName"
              label={t('lastName')}
              type="text"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getError('lastName')}
            />
          </>
        )}

        <InputRow
          name="email"
          label={t('customerEmail')}
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('email')}
        />

        <InputRow
          name="phone"
          label={t('customerPhone')}
          type="text"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('phone')}
        />

        <FormRow>
          <h4 style={{ display: 'flex' }}>{` ${t('paymentDetails')} `}</h4>
        </FormRow>

        <Row className={cls('section')}>
          <Col md={9} sm={12} xs={12} style={{ paddingRight: 'O' }}>
            <Input
              style={{
                border: uniqueRefFailed ? '1px solid #FF4D4A' : '',
                boxSizing: uniqueRefFailed ? 'border-box' : '',
              }}
              name="orderNumber"
              label={`${t('paymentReference')} *`}
              type="text"
              value={uniqueRef}
              onChange={handleChangeRef}
              onBlur={handleBlurRef}
              error={getError('orderNumber')}
            />
            {uniqueRefFailed && (
              <span style={{ position: 'absolute' }}>
                <FormError>{t('paymentReferenceAlreadyExists')}</FormError>
              </span>
            )}
          </Col>
          <Col md={3} sm={12} xs={12} style={{ paddingLeft: 'O' }}>
            <button
              style={{ backgroundColor: '#ffffff', border: 'none', height: '100%' }}
              type="button"
              onClick={handleGenerateNumberClick}
            >
              {generationLoading ? (
                <>{t('generating')}</>
              ) : (
                <>
                  {t('generate')} <Icon type="generate" />
                </>
              )}
            </button>
          </Col>
        </Row>
        <Row className={cls('section')}>
          <Col md={12}>
            <Input
              name="orderTotal"
              label={`${t('paymentAmount')} *`}
              type="text"
              value={values.orderTotal}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getError('orderTotal')}
            />
            {errors.orderTotal && touched.orderTotal ? (
              <FormError>{errors.orderTotal}</FormError>
            ) : null}
          </Col>
        </Row>
        {appState.canRestrictCheckoutType && (
          <Row className={cls('section')}>
            <Col md={12}>
              <h4 style={{ display: 'flex' }}>{` ${t('paymentTypeColon')} `}</h4>
              <RadioGroup
                alignment="horizontal"
                // onChange={handleChange}
                // onBlur={handleBlur}
                // name="test"
              >
                {appState.planRates.map(planRate => {
                  const { slug } = planRate;
                  if (planRate.is_honeymoon) {
                    return <></>;
                  }
                  return (
                    <RadioGroup.Button
                      key={slug}
                      name="plan"
                      value={slug}
                      checked={selectedPlanRateSlug === slug}
                      onChange={() => {
                        setSelectedPlanRateSlug(slug);
                        formik.values.slug = slug;
                        formik.validateForm();
                      }}
                    >
                      {t(snakeToCamel(slug))}
                    </RadioGroup.Button>
                  );
                })}
              </RadioGroup>
            </Col>
          </Row>
        )}
        {/* <Row className={cls('section')}>
          <Col md={12}>
            <label htmlFor="provideDetails">
              Provide more details:
              <input
                style={{ marginLeft: '15px' }}
                name="provideDetails"
                type="checkbox"
                checked={provideDetails}
                onChange={handleProvideDetailsChange}
              />
            </label>
          </Col>
        </Row> */}
        {provideDetails && (
          <>
            <Row className={cls('section')}>
              <Col md={4}>
                <Input
                  name="taxAmount"
                  label={t('tax')}
                  type="text"
                  value={values.taxAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError('taxAmount')}
                />
              </Col>
              <Col md={4}>
                <Input
                  name="shippingAmount"
                  label={t('shipping')}
                  type="text"
                  value={values.shippingAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError('shippingAmount')}
                />
              </Col>
              <Col md={4}>
                <Input
                  name="discount"
                  label={t('discount')}
                  type="text"
                  value={values.discount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError('discount')}
                />
              </Col>
            </Row>

            <FormRow>
              <h4>{` ${t('paymentLineItemDetails')} `}</h4>
            </FormRow>

            <Row className={cls('section')}>
              <Col md={4}>
                <Input
                  name="itemTitle"
                  label={t('itemTitle')}
                  type="text"
                  value={values.itemTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError('itemTitle')}
                />
              </Col>
              <Col md={4}>
                <Input
                  name="unitPrice"
                  label={t('unitPrice')}
                  type="text"
                  value={values.unitPrice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError('unitPrice')}
                />
              </Col>
              <Col md={4}>
                <Input
                  name="quantity"
                  label={t('quantity')}
                  type="text"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError('quantity')}
                />
              </Col>
            </Row>
          </>
        )}
        <FormRow>
          <h4 style={{ display: 'flex' }}>{` ${t('commentsColon')} `}</h4>
        </FormRow>

        <TextArea
          name="description"
          placeholder={t('comments')}
          type="text"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={getError('description')}
        />
        {linkBankAccount && (
          <p style={{ maxWidth: '30rem', textAlign: currLang === 'ar' ? 'right' : 'left' }}>
            {t('linkBankAccountDesc')}
          </p>
        )}

        <FormRow type="submit">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            loading={isSubmitting}
            disabled={isSubmitting || uniqueRefFailed}
          >
            {t('createPaymentLink')}
          </Button>
        </FormRow>
      </Form>
    </div>
  );
};

OrderPaymentForm.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
  continueTo: PropTypes.string,
  onSuccess: PropTypes.func,
  prefill: PropTypes.shape({
    confirmCallBackURL: PropTypes.string,
    rejectCallBackURL: PropTypes.string,
  }),
};

OrderPaymentForm.defaultProps = {
  continueTo: null,
  onSuccess: null,
  prefill: null,
};

export default OrderPaymentForm;
