export default function formatCurrency(phone) {
  if (!phone) {
    return '';
  }

  return phone
    .replace('+971', '')
    .replace('+966', '')
    .replace('+968', '')
    .replace('+965', '');
}
