/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Input from 'spotii-ui/Input';
import Button from 'spotii-ui/Button';
import Currency from 'spotii-ui/Currency';
import { DateRangePicker } from 'components/DatePicker';
import StatusPicker from 'components/StatusPicker';
import SourcePicker from 'components/SourcePicker';
import TableView from 'components/TableView/TableView';
import Pagination from 'components/Pagination';
import CheckoutStatus from 'components/CheckoutStatus';
import OverlayIcon from 'components/OverlayIcon';
import { sendPaymentLinkToCustomer } from 'api/index';
import {
  ORDER_STATUS_COMPLETED,
  CHECKOUT_STATUS_SUBMITTED,
  CHECKOUT_STATUS_FAILED,
} from 'utils/constants';
import {
  fetchCheckouts,
  changeDates,
  changeStatus,
  changeSource,
  changeQueryFilter,
  changePage,
  reset,
} from 'reducers/checkouts';

import './CheckoutList.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import copyTextToClipboard from '../../utils/copyToClipboard';
import QRCodeModal from './QRCodeModal';

const CHECKOUTS_PER_PAGE = 10;
const cls = b.with('checkouts-list');

const handleSendLink = (e, row) => {
  // Sends link email to customer
  sendPaymentLinkToCustomer(row.reference);

  // Handle click on "send link" as well as on svg icon
  const toChange = e.target.childNodes[0].nodeType === 3 ? e.target.parentNode : e.target;
  const initialContent = toChange.innerHTML;

  // Handle countdown
  toChange.parentNode.disabled = true;
  toChange.removeChild(toChange.childNodes[0]);
  toChange.innerHTML = 'Resend in 30s';
  let timer = 30;
  function setTimer() {
    if (timer > 0) {
      timer -= 1;
      toChange.innerHTML = `Resend in ${timer}s`;
    } else {
      // eslint-disable-next-line no-use-before-define
      clearInterval(resend);
      toChange.parentNode.disabled = false;
      toChange.innerHTML = initialContent;
    }
  }
  const resend = setInterval(setTimer, 1000);
};

//
// Component
//
const CheckoutList = ({ appState }) => {
  const currLang = useSelector(state => state.language.language);
  const { t } = useTranslation();
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [clickedPaymentLink, setClickedPaymentLink] = useState(null);

  const handleQRCodeClick = rowData => {
    setShowQRCodeModal(true);
    setClickedPaymentLink(rowData);
  };

  // Columns render options
  const columns = [
    {
      title: <div className={`column-title-${currLang}`}>{t('createdDate')}</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: value => (
        <div className={`line-height column-render-${currLang}`}>
          <div
            className="checkouts-list__date"
            title={moment.parseZone(value).format('YYYY-MM-DD HH:mm:ss')}
          >
            {moment.parseZone(value).format('YYYY-MM-DD')}
          </div>
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('status')}</div>,
      dataIndex: 'status',
      key: 'status',
      render: value => {
        return (
          <div className={`column-status-${currLang}`}>
            <CheckoutStatus status={value} badge />
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('reference')}</div>,
      dataIndex: 'reference',
      key: 'reference',
      render: (value, row) => (
        <div className={`line-height column-render-${currLang}`}>
          <div>{row.displayReference || row.reference}</div>
          {/* <div className="checkouts-list__sub">{`${t('spotiiID')} ${value.toUpperCase()}`}</div> */}
        </div>
      ),
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('advisor')}</div>,
      dataIndex: 'order',
      key: 'order',
      render: value => {
        return (
          <div className={`line-height column-render-${currLang}`}>
            {value.employee
              ? `${value.employee.info.user.first_name} ${value.employee.info.user.last_name}`
              : ''}
          </div>
        );
      },
    },

    {
      title: <div className={`column-title-${currLang}`}>{t('orderTotal')}</div>,
      dataIndex: 'total',
      key: 'total',
      render: (value, row) => {
        return (
          <div className={`line-height column-render-${currLang}`}>
            <Currency currency={row.currency} amount={value} />
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('paymentLink')}</div>,
      dataIndex: 'checkout_url',
      key: 'checkout_url',
      render: (value, row) => {
        if (row.status === CHECKOUT_STATUS_FAILED) {
          return (
            <div className={`line-height column-render-${currLang}`}>
              <OverlayIcon type="failure" overlayMessage="Order has expired" />
            </div>
          );
        }
        if (row.status === CHECKOUT_STATUS_SUBMITTED) {
          return (
            <div className={`line-height column-render-${currLang}`}>
              <OverlayIcon type="success" overlayMessage="Order completed" />
            </div>
          );
        }
        return (
          <div className={`column-button-${currLang}`}>
            <Button
              className={cls(`copy-to-clip-${currLang}`)}
              size="small"
              icon="copy"
              shape="square"
              type="transparent"
              onClick={() => copyTextToClipboard('Payment Link', row.checkoutUrl)}
            >
              {t('copy')}
            </Button>
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('sendLink')}</div>,
      dataIndex: 'send_url',
      key: 'send_url',
      render: (value, row) => {
        if (row.status === CHECKOUT_STATUS_SUBMITTED) {
          return (
            <div className={`line-height column-render-${currLang}`}>
              <OverlayIcon type="success" overlayMessage="Order completed" />
            </div>
          );
        }
        if (row.status === CHECKOUT_STATUS_FAILED) {
          return (
            <div className={`line-height column-render-${currLang}`}>
              <OverlayIcon type="failure" overlayMessage="Order expired" />
            </div>
          );
        }
        if (row.order.customer && row.order.customer.email) {
          return (
            <div className={`column-button-${currLang}`}>
              <Button
                size="small"
                icon="send"
                shape="square"
                type="transparent"
                onClick={e => handleSendLink(e, row)}
              >
                {t('email')}
              </Button>
            </div>
          );
        }
        return (
          <div className={`line-height column-render-${currLang}`}>
            <OverlayIcon type="failure" overlayMessage="No customer email provided" />
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('rejectionsColumn')}</div>,
      dataIndex: 'rejections',
      key: 'rejections',
      render: (value, row) => {
        let displayVal = '_';
        let styleClass = 'column-render-remarks';
        if (value.length && row.status !== 'SUBMITTED' && row.status !== ORDER_STATUS_COMPLETED) {
          styleClass = `column-render-${currLang}`;
          displayVal = value[0]
            .split('|')[1]
            .split('_')
            .join(' ');
        }
        return (
          <div className={`line-height ${styleClass}`} style={{ fontSize: '0.7rem', color: '#656666' }}>
            {displayVal.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: <div className={`column-title-${currLang}`}>{t('scanQrCode')}</div>,
      dataIndex: 'payment_qr_code',
      key: 'payment_qr_code',
      render: (value, row) => {
        if (row.status === CHECKOUT_STATUS_SUBMITTED) {
          return (
            <div className={`line-height column-render-${currLang}`}>
              <OverlayIcon type="success" overlayMessage="Payment completed" />
            </div>
          );
        }
        if (row.status === CHECKOUT_STATUS_FAILED) {
          return (
            <div className={`line-height column-render-${currLang}`}>
              <OverlayIcon type="failure" overlayMessage="Order expired" />
            </div>
          );
        }
        return (
          <Button
            className={`column-button-${currLang}`}
            size="small"
            icon="scan-qr-code"
            shape="square"
            type="transparent"
            onClick={() => handleQRCodeClick(row)}
          >
            QR
          </Button>
        );
      },
    },
  ];

  const userHasPerm = !(appState.loggedEmployee.role !== null);
  // Admin : has no role or has all permissions
  const userIsAdmin =
    userHasPerm || appState.loggedEmployee.role.permissions.includes('checkouts_readall');

  const dispatch = useDispatch();
  const {
    isLoading,
    isResolved,
    list,
    search,
    fromDate: stateFromDate,
    toDate: stateToDate,
    status,
    source,
    pageCount,
    currentPage,
  } = useSelector(state => state.checkouts);
  // dates should be a MomentJS instance
  const initialStartDate = stateFromDate ? moment(stateFromDate) : null;
  const initialEndDate = stateToDate ? moment(stateToDate) : null;

  const [prevCheckoutStatus, setPrevCheckoutStatus] = useState(null);
  const [prevCheckoutSource, setPrevCheckoutSource] = useState(null);
  const [checkoutStatus, setCheckoutStatus] = useState(null);
  const [checkoutSource, setCheckoutSource] = useState(null);
  const [orderFilter, setOrderFilter] = useState(null);
  const [prevOrderFilter, setPrevOrderFilter] = useState(null);
  const [dateFocus, setDateFocus] = useState(null);
  const [fromDate, setFromDate] = useState(initialStartDate);
  const [toDate, setToDate] = useState(initialEndDate);

  const page = Math.max(currentPage, 1) - 1;
  const fetchParams = {
    fromDate: stateFromDate,
    toDate: stateToDate,
    search: orderFilter,
    status: checkoutStatus,
    source: checkoutSource,
    limit: CHECKOUTS_PER_PAGE,
    page: currentPage,
  };
  // handlers
  const changeOrderFilter = () => {
    if (!prevOrderFilter || (prevOrderFilter && prevOrderFilter !== orderFilter)) {
      dispatch(changeQueryFilter({ search: orderFilter, currentPage: 1 }));
      setPrevOrderFilter(orderFilter);
    } else {
      dispatch(changeQueryFilter({ search: orderFilter, currentPage }));
    }
  };
  const onPageChange = p => dispatch(changePage(p.selected + 1));

  const handleOrderActionSuccess = () =>
    // Resend fetch request with same params
    dispatch(fetchCheckouts(fetchParams));

  const handleStatusPickerChange = evt => {
    setCheckoutStatus(evt.value);
    if (!prevCheckoutStatus || (prevCheckoutStatus && prevCheckoutStatus !== checkoutStatus)) {
      dispatch(changeStatus({ status: evt.value, currentPage: 1 }));
      setPrevCheckoutStatus(checkoutStatus);
    } else {
      dispatch(changeStatus({ status: evt.value, currentPage }));
    }
  };

  const handleSourcePickerChange = evt => {
    setCheckoutSource(evt.value);
    if (!prevCheckoutSource || (prevCheckoutSource && prevCheckoutSource !== checkoutSource)) {
      dispatch(changeSource({ source: evt.value, currentPage: 1 }));
      setPrevCheckoutSource(checkoutSource);
    } else {
      dispatch(changeSource({ source: evt.value, currentPage }));
    }
  };

  // Add Actions column to the table view
  const getExtendedColumns = () => {
    // Add Actions column to the table view
    let columnsExtended = columns;

    columnsExtended = appState.displayRejections
      ? columnsExtended.slice()
      : columnsExtended.filter(x => x.key !== 'rejections');

    columnsExtended = appState.showPaymentLinkQr
      ? columnsExtended.slice()
      : columnsExtended.filter(x => x.key !== 'payment_qr_code');

    return columnsExtended;
  };

  // Reset reducer on page view
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(reset()), []);

  useEffect(() => {
    dispatch(fetchCheckouts(fetchParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutStatus, search, checkoutSource, stateFromDate, stateToDate, currentPage]);

  return (
    <div className={cls()}>
      <div className={cls('controls')}>
        <DateRangePicker
          // Allow to select only one date
          t={t}
          currLang={currLang}
          minimumNights={0}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          startDateId="startDate"
          endDateId="endDate"
          startDate={fromDate}
          endDate={toDate}
          onDatesChange={({ startDate, endDate }) => {
            setFromDate(startDate);
            setToDate(endDate);
          }}
          focusedInput={dateFocus}
          onFocusChange={focusedInput => {
            setDateFocus(focusedInput);
          }}
          customArrowIcon="–"
          // Allow to select any date
          isOutsideRange={() => {}}
          onClose={({ startDate, endDate }) => {
            dispatch(
              changeDates({
                fromDate: startDate ? startDate.format('YYYY-MM-DD') : null,
                toDate: endDate ? endDate.format('YYYY-MM-DD') : null,
              }),
            );
          }}
        />
        <Input
          label={t('search')}
          placeholder={t('paymentLinkSearch')}
          name="Search"
          style={{
            marginLeft: 3,
            marginRight: 3,
            lineHeight: '20px',
            paddingTop: '23px',
            paddingLeft: '15px',
            paddingBottom: '10px',
          }}
          value={orderFilter}
          onChange={e => setOrderFilter(e.target.value)}
          onKeyPress={e => (e.key === 'Enter' ? changeOrderFilter() : null)}
        />
        <StatusPicker onStatusChange={handleStatusPickerChange} selectedStatus={status} />
        <SourcePicker onSourceChange={handleSourcePickerChange} selectedSource={source} />
      </div>
      <TableView
        columns={getExtendedColumns()}
        data={
          userIsAdmin
            ? list
            : list.filter(
                el =>
                  el.order.employee !== null &&
                  el.order.employee.employee_id === appState.loggedEmployee.employee_id,
              )
        }
        loading={isLoading}
        rowKey="reference"
      />
      {isResolved && list.length ? (
        <div className={cls('pagination')}>
          <Pagination current={page} count={pageCount} onChange={onPageChange} />
        </div>
      ) : null}
      {clickedPaymentLink && (
        <QRCodeModal
          show={showQRCodeModal}
          setShowQRCodeModal={setShowQRCodeModal}
          paymentLinkData={clickedPaymentLink}
        />
      )}
    </div>
  );
};

CheckoutList.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};
export default CheckoutList;
